.Container {
  width: 375px;
  height: auto;
}
.LoginBanner {
  position: relative;
}
.LoginHeroImg {
  width: 100%;
  height: auto;
}
.SignUpButton {
  font-weight: 500 !important;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  position: relative;
  color: #486877;
  cursor: pointer;
  margin-left: 12px;
}
.SignUpButton:hover {
  color: #569ca4;
}
.ForgotPassword {
  font-weight: 500;
  padding-bottom: 16px;
  display: grid;
  justify-content: center;
  text-align: center;
  position: relative;
  color: #486877;
  cursor: pointer;
}
.ForgotPassword:hover {
  color: #569ca4;
}
.DeclineLink,
.DeclineLink:hover {
  color: #e53451;
  width: 180px;
  margin: auto;
}

.DeclineLink:hover {
  text-decoration: underline !important;
}
.DeclineCloseIcon {
  margin: 0;
  cursor: pointer;
}
.CircleIconBox {
  background-color: #ffffff;
  border: 4px solid rgba(243, 239, 234, 1);
  border-radius: 500px;
  height: 250px;
  width: 250px;
  box-shadow: 0px 0px 10px #eae9e8;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}
.FormMessageBox {
  display: flex;
  align-content: center;
  margin-bottom: 10px;
}
.ContainerCenter {
  transform: translate(0%, 50%);
  position: relative;
}
.HeadingIcon {
  margin-right: 8px;
}
.FormMessageIcon {
  margin-right: 8px;
  display: inline-flex;
}
.FormMessageText {
  display: inline-flex;
}
.TabBox {
  padding: 16px 16px;
}
.Splash {
  display: flex;
  flex-grow: 1;
  height: 100vh;
  background-repeat: repeat;
  background-position: top center;
  background-size: auto;
  background-attachment: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}
.AuthRoot {
  display: flex;
  flex-grow: 1;
  height: 100vh;
  background: #e5ebf1;
  overflow-y: auto;
  overflow-x: hidden;
}
.AuthPaper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  margin: 32px;
  min-height: 50%;
}

@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .Container {
    width: 100%;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .AuthPaper {
    padding: 16px;
    min-width: 80%;
    min-height: auto;
  }
}
