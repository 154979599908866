.MainLogobox {
  display: flex;
  justify-content: center;
}
.MainLogo {
  display: flex;
}
@media screen and (min-device-width: 320px) and (max-device-width: 359px) {
  .MainLogo {
    width: 190px;
  }
}